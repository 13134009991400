@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
}

* {
  box-sizing: border-box;
}

.container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.style_header__MlSQ_ {
  height: 60px;
  color: white;
  background: #b5b5b5;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.18);
  width: 100%;
}

.style_container__3TbXE {
  display: flex;
  justify-content: space-between;
}

.style_title__dZdD7 {
  display: flex;
  font-size: 1.5rem;
  color: white;
  line-height: 26px;
  margin: 0;
  font-weight: 500;
  padding: 17px 0;
}

.style_svg__2LCtJ {
  height: 36px;
  width: 36px;
  fill: black;
  margin: 2px 5px 0 0;
}

@media screen and (max-width: 39.9375em) {
  .style_titleText__39Brg{display:none;}
  .style_container__3TbXE{
    padding:0 15px;
  }
}

.style_about__2tzbn {
  font-size: 1.5rem;
  color: white;
  display: inline-flex;
  padding: 0 8px 0 6px;
  border-radius: 4px;
  height: 40px;
  background-color: #e8e8e8;
  margin-top: 10px;
}

a {
  display: inline-flex;
  font-size: 1rem;
  line-height: 26px;
  padding: 9px 0;
  margin: 0;
  font-weight: 500;
  color: #ffffff;
}

.style_container__1auuv {
  background: #dadada; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #dadada,
    #dadada
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.style_stackV__1th-q {
  display: flex;
  flex-direction: column;
}

.style_stackH__3iWMK {
  display: flex;
}

.style_semiLeft__1SJ4t {
  height: 50px;
  width: 100px;
  border-radius: 25px;
  background: linear-gradient(to right, rgb(242, 78, 30) 50%, rgb(255, 114, 98) 50%); 
}

.style_semiLeftSecond__nbzdp {
  height: 50px;
  width: 50px;
  border-radius: 25px 0 0 25px;
  background-color: rgb(162, 89, 255);
}

.style_semiRight__3tKic {
  height: 50px;
  width: 50px;
  border-radius: 0 25px 25px 0;
  background-color: rgb(255, 114, 98);
}

button {
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
}

.style_wrapper__1I-nL { 
	width: 100%; 
	height: 100%; 
	position: absolute; 
} 
 
.style_wrapper__1I-nL img { 
	width: 100%; 
	height: 100%; 
} 
 
.style_centered__txb2x {
  text-shadow: 1.5px 1.5px #282a35;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.style_container__2tGcP {
  background: #dadada; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #dadada,
    #dadada
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.style_stackV__3S6TB {
  display: flex;
  flex-direction: column;
}

.style_stackH__e8Tn2 {
  display: flex;
}

.style_semiLeft__1Nubi {
  height: 50px;
  width: 100px;
  border-radius: 25px;
  background: linear-gradient(to right, rgb(242, 78, 30) 50%, rgb(255, 114, 98) 50%); 
}

.style_semiLeftSecond__1Jtw0 {
  height: 50px;
  width: 50px;
  border-radius: 25px 0 0 25px;
  background-color: rgb(162, 89, 255);
}

.style_semiRight__32S7m {
  height: 50px;
  width: 50px;
  border-radius: 0 25px 25px 0;
  background-color: rgb(255, 114, 98);
}

button {
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
}

.style_pk_video__eCzAQ {
     background-color: black !important; /* or whatever you want */
}

.style_wrapper__1kad4 { 
        width: 100%; 
        height: 100%; 
        position: absolute; 
} 
 
.style_wrapper__1kad4 img { 
        width: 100%; 
        height: 100%; 
} 
 
.style_centered__pnWbx {
  text-shadow: 1.5px 1.5px #282a35;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}


.container {
  background: #dadada; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #dedede,
    #dedede
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #dadada,
    #dadada
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.stackV {
  display: flex;
  flex-direction: column;
}

.stackH {
  display: flex;
}

.semiLeft {
  height: 50px;
  width: 100px;
  border-radius: 25px;
  background: linear-gradient(to right, rgb(242, 78, 30) 50%, rgb(255, 114, 98) 50%); 
}

.semiLeftSecond {
  height: 50px;
  width: 50px;
  border-radius: 25px 0 0 25px;
  background-color: rgb(162, 89, 255);
}

.semiRight {
  height: 50px;
  width: 50px;
  border-radius: 0 25px 25px 0;
  background-color: rgb(255, 114, 98);
}

button {
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
}

.wrapper { 
	width: 100%; 
	height: 100%; 
	position: absolute; 
} 
 
.wrapper img { 
	width: 100%; 
	height: 100%; 
} 
 
.centered {
  text-shadow: 1.5px 1.5px #282a35;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
}

* {
  box-sizing: border-box;
}

.container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.header {
  height: 60px;
  color: white;
  background: #b5b5b5;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.18);
  width: 100%;
}

.container {
  display: flex;
  justify-content: space-between;
}

.title {
  display: flex;
  font-size: 1.5rem;
  color: white;
  line-height: 26px;
  margin: 0;
  font-weight: 500;
  padding: 17px 0;
}

.svg {
  height: 36px;
  width: 36px;
  fill: black;
  margin: 2px 5px 0 0;
}

@media screen and (max-width: 39.9375em) {
  .titleText{display:none;}
  .container{
    padding:0 15px;
  }
}

.about {
  font-size: 1.5rem;
  color: white;
  display: inline-flex;
  padding: 0 8px 0 6px;
  border-radius: 4px;
  height: 40px;
  background-color: #e8e8e8;
  margin-top: 10px;
}

a {
  display: inline-flex;
  font-size: 1rem;
  line-height: 26px;
  padding: 9px 0;
  margin: 0;
  font-weight: 500;
  color: #ffffff;
}
